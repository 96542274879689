<template>
  <div>
    <SubscribeModal :term="p(query)" :active="modal" @dismissed="modal=false"/>
    <div v-if="isBusy">
      <div class="flex flex-col items-center justify-center w-screen h-screen">
        <svg width="8rem" height="8rem" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M278 416L388.5 305.5L278 195" stroke="#7582F0" stroke-width="40" stroke-linecap="round" stroke-linejoin="round" class="loader-path" />
          <path d="M255.5 306L145.5 196L255.5 86" stroke="#7582F0" stroke-width="40" stroke-linecap="round" stroke-linejoin="round"   class="loader-path" />
        </svg>

        <div class="mt-3 text-lg font-bold text-purple-500">
          Searching..
        </div>
      </div>
    </div>
    <div v-else>
      <div class="hero-clip md:pl-12 md:pr-12">
        <div class="flex flex-row pt-4 pb-4 align-middle md:pt-10 md:pb-10">
          <div class="ml-4 mr-10">
            <a href="#">
              <img src="../assets/logo.svg" alt="Sijilat - Logo" />
            </a>
          </div>
          <div class="mr-8 text-lg text-gray-600">
            <router-link :to="{ name: 'root' }">&larr; Search again</router-link>
          </div>
        </div>
      </div>

      <div class="mt-10 lg:pl-12 lg:pr-12">
        <h1 class="ml-4 text-4xl font-extrabold leading-tight text-gray-100">Results for "{{query}}"</h1>

        <div
          class="mt-1 ml-4 text-lg text-gray-300"
        >We found {{count == 200 ? "over 200" : count}} relevant matches for your search term.</div>

        <div v-if="results.people.length > 0">
          <div class="mt-16 mb-4 ml-6 text-2xl font-bold text-gray-200">
            <img src="../assets/icon-people.svg" class="relative inline mr-1" style="top: -.2rem;" /> People
          </div>

          <div class="flex flex-col">
            <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-700 shadow-sm sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >Name</th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >Name (Arabic)</th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >Nationality</th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >Roles</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                      v-for="(result, index) in results.people"
                      v-bind:key="index"
                      :class="index % 2 == 1 ? 'bg-gray-900' : 'bg-white'"
                      v-on:click="showModal('person', result['name_en'])"
                      class="cursor-pointer"
                    >
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div class="text-sm font-bold leading-5 text-gray-100">{{p(result['name_en'])}}</div>
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div class="leading-5 text-gray-300 font-arabic">{{result['name_ar']}}</div>
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div class="text-sm leading-5 text-gray-300">{{p(result['nationality'])}}</div>
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div class="text-sm leading-5 text-gray-300">{{p(result['role'])}}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="results.businesses.length > 0">
          <div class="mt-16 mb-4 ml-6 text-2xl font-bold text-gray-200">
            <img src="../assets/icon-businesses.svg" class="relative inline mr-1" style="top: -.2rem;" /> Businesses
          </div>

          <div class="flex flex-col">
            <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-700 shadow-sm sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >Company</th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >Name (Arabic)</th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >CR Number</th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-300 uppercase bg-gray-900 border-b border-gray-700"
                      >Status</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                      v-for="(result, index) in results.businesses"
                      v-bind:key="index"
                      :class="index % 2 == 1 ? 'bg-gray-900' : 'bg-white'"
                      v-on:click="showModal('business', result['cr_en'])"
                      class="cursor-pointer"
                    >
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div
                          class="text-sm font-bold leading-5 text-gray-100"
                        >{{p(result['cr_en'])}}</div>
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div class="leading-5 text-gray-300 font-arabic">{{result['cr_ar']}}</div>
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div class="text-sm leading-5 text-gray-300">{{result['no']}}</div>
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-700">
                        <div class="text-sm leading-5 text-gray-300">
                          <span
                            class="p-1 pl-2 pr-2 rounded-full"
                            :class="result['status'] == 'ACTIVE' ? 'active': 'inactive'"
                          >{{result['status']}}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-16 mb-24 ml-4 text-lg text-gray-200">
          Need more detailed results, advanced search filters and more?
          <router-link :to="{ name: 'root', hash: '#pricing' }" class="text-purple-500">Subscribe now</router-link>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import SubscribeModal from "../components/subscribe_modal";

export default {
  metaInfo: {
    title: "Search Results | Sijilat.io",
  },
  data() {
    return {
      query: "",
      isBusy: true,
      results: null,
      modal: false, /* is the modal visible? */
    };
  },
  components: {
    Footer,
    SubscribeModal
  },
  mounted: function () {
    this.query = this.$route.query.q;
    this.performQuery();
    this.$analytics.logEvent('view-results', { term: this.query })
  },
  computed: {
    count: function() {
      return this.results.people.length + this.results.businesses.length
    }
  },
  methods: {
    p: function(text) {
      // Humanizes the text "BATELCO INTERNATIONAL COMPANY B S C C" => "Batelco International Company B S C C"
      return text
        .split(' ')
        .map((w) => w.slice(0, 1).toUpperCase() + w.slice(1).toLowerCase())
        .join(' ')
    },
    performQuery: function () {
      const vm = this;
      vm.isBusy = true;

      vm.axios
        .get(
          "https://vmt2ncnatf.execute-api.me-south-1.amazonaws.com/default/sijilatQuery",
          { params: { q: vm.query } }
        )
        .then((response) => {
          vm.results = response.data;
        })
        .catch((error) => {
          // TODO: Handler errors
          console.error(error);
        })
        .finally(() => {
          // Always executed
          vm.isBusy = false;
        });
    },
    showModal: function(type, label) {
      const term = this.term
      this.$analytics.logEvent('search-result-clicked', { term, type, label })

      this.modal = true // show modal
    }
  },
};
</script>

<style lang="scss" scoped>
.hero-clip {
  clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
  background: rgba(29, 30, 43, 0.96);
  max-width: 770px;
}
.active {
  background-color: #e4f1ea;
  color: #52a777;
}
.inactive {
  background-color: #f1e6e4;
  color: #a75752;
}

/* */
.loader-path {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: dash 1s ease-in-out alternate infinite;
}

.loader-path:nth-child(2) {
  animation-delay: .5s;
}

@keyframes dash {
  from {
    stroke-dashoffset: 400;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
