<template>
  <div>
    <footer class="block pt-4 pb-12 pl-4 pr-4 bg-gray-100 lg:pl-12 lg:pr-12 lg:pt-24 lg:pb-24">
      <div class="flex-row pt-10 md:flex">
        <div class="flex-grow">
          <img src="../assets/logo.svg" title="Sijilat Logo" />
          <div class="mt-4 text-lg text-gray-300">&copy; Sijilat, Inc 2022</div>
        </div>
        <div style="min-width:220px;">
          <div class="mt-12 font-bold text-gray-300 uppercase md:mt-0">Product</div>
          <div>
            <div class="mt-5 text-lg text-gray-500">
              <a href="#pricing">Pricing</a>
            </div>
            <div class="mt-5 text-lg text-gray-500">
              <a href="#features">Features</a>
            </div>
          </div>
        </div>
        <div style="min-width:220px;">
          <div class="mt-12 font-bold text-gray-300 uppercase md:mt-0">Legal</div>
          <div>
            <div class="mt-5 text-lg text-gray-500">
              <router-link :to="{ name: 'privacy' }">Privacy Policy</router-link>
            </div>
            <div class="mt-5 text-lg text-gray-500">
              <router-link :to="{ name: 'terms-of-use' }">Terms of Use</router-link>
            </div>
          </div>
        </div>
        <div style="min-width:220px;">
          <div class="mt-12 font-bold text-gray-300 uppercase md:mt-0">Users</div>
          <div>
            <div class="mt-5 text-lg text-gray-500">
              <router-link :to="{ name: 'login' }">Login</router-link>
            </div>
            <div class="mt-5 text-lg text-gray-500">
              <a href="#pricing">Register</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>