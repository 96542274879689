<template>
  <div>
    <div class="hero hidden md:block absolute">
      <div class="hero-clip lg:pl-12 pr-12">
        <div class="pt-10 pb-10 flex flex-row align-middle">
          <div class="mr-10">
            <a href="#">
              <img src="../assets/logo.svg" alt="Sijilat - Logo" />
            </a>
          </div>
          <div class="text-lg text-gray-600 mr-8">
            <router-link :to="{ name: 'root' }">&larr; Return to main site</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-center items-center h-screen w-screen">
      <div class="mt-10 text-center max-w-lg text-lg text-gray-300">
        <div class="text-4xl mb-6">🎉</div>
        <div>
          Wow, thank you so much for signing up! I'll be sending you an email over the next 24 hours with more details.
        </div>
        <div class="mt-6">
          (Yes, I do registrations by hand for now)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  mounted: function() {
    this.$analytics.logEvent('view-register')
  },
  components: {
  }
};
</script>

<style lang="scss" scoped>
.hero-clip {
  clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
  background: rgba(29, 30, 43, 0.96);
  max-width: 770px;
  width: 60vw;
}
.input-container {
  position: relative;

  img {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  input {
    padding-left: 40px;
  }
}
</style>