<template>
  <div>
    <div class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10" :class='active ? "opacity-100 ease-out duration-300" : "pointer-events-none opacity-0 ease-in duration-200"'>
      <div class="fixed inset-0 transition-opacity" v-on:click="dismiss">
        <div class="absolute inset-0 bg-gray-100 opacity-75"></div>
      </div>

      <div class="bg-gray-100 rounded-lg overflow-hidden shadow-xl transform transition-all md:max-w-3xl sm:max-w-lg sm:w-full relative opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" :class='active ? "ease-out duration-300 opacity-100 translate-y-0 sm:scale-100": "ease-in duration-200"' role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <img src="../assets/curl.svg" class="absolute" style="right: -20px;top:-10px;">
        <div class="flex flex-col p-4 md:p-8 md:pt-10">
          <div class="md:w-2/3">
            <div class="text-4xl font-bold text-gray-800 leading-tight">
              Everything <span class="hidden md:inline">you want</span> to know about <span class="text-purple-500">{{term}}</span> <span class="hidden md:inline">and 200,000+ others..</span>
            </div>
            <div class="text-gray-400 mt-2 md:mt-6 text-lg">With plans starting at <span class="font-bold text-gray-600">just $79/month</span>, sign up now to unlock access to:</div>
          </div>
          <div class="md:flex flex-wrap">
            <div class="mt-5 md:mt-10 flex items-start md:w-1/2">
              <div class="flex-none">
                <img src="../assets/plus.svg" style="width:50px;" />
              </div>
              <div class="ml-3">
                <div class="font-bold text-lg text-gray-400">Contact details</div>
                <div
                  class="leading-normal text-gray-300"
                >including phone &amp; email</div>
              </div>
            </div>

            <div class="mt-5 md:mt-10 flex items-start md:w-1/2">
              <div class="flex-none">
                <img src="../assets/plus.svg" style="width:50px;" />
              </div>
              <div class="ml-3">
                <div class="font-bold text-lg text-gray-400">Investment history</div>
                <div
                  class="leading-normal text-gray-300"
                >available since 1966</div>
              </div>
            </div>

            <div class="mt-5 md:mt-10 flex items-start md:w-1/2">
              <div class="flex-none">
                <img src="../assets/plus.svg" style="width:50px;" />
              </div>
              <div class="ml-3">
                <div class="font-bold text-lg text-gray-400">Business ties</div>
                <div
                  class="leading-normal text-gray-300"
                >across all businesses</div>
              </div>
            </div>

            <div class="mt-5 md:mt-10 flex items-start md:w-1/2">
              <div class="flex-none">
                <img src="../assets/plus.svg" style="width: 50px;"/>
              </div>
              <div class="ml-3">
                <div
                  class="leading-normal text-gray-300"
                >work address, labor <br/>violations and more...</div>
              </div>
            </div>
          </div>

          <div class="text-center mt-6 md:mt-10">
            <a class="bg-purple-500 pt-3 pb-3 block md:inline-block pl-12 pr-12 text-gray-700 rounded-full font-bold" href="/#pricing">Sign up now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'term', 'active' ],
  name: "SubscribeModal",
  data() {
    return {
    }
  },
  methods: {
    dismiss: function() {
      this.$emit('dismissed');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>