<template>
  <div>
    <div v-if="success" ref="retool" class="h-screen"></div>
    <div v-else>
      <div class="hidden absolute hero md:block">
        <div class="pr-12 hero-clip lg:pl-12">
          <div class="flex flex-row pt-10 pb-10 align-middle">
            <div class="mr-10">
              <a href="#">
                <img src="../assets/logo.svg" alt="Sijilat - Logo" />
              </a>
            </div>
            <div class="mr-8 text-lg text-gray-600">
              <router-link :to="{ name: 'root' }"
                >&larr; Return to main site</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-center items-center w-screen h-screen">
        <div>
          <img src="../assets/logo-black.svg" title="Logo" />
        </div>
        <div class="mt-10 text-lg text-gray-300" v-if="!error">
          Let’s get you signed in to your account.
        </div>
        <div
          class="pt-2 pr-4 pb-2 pl-4 mt-10 text-lg text-red-500 bg-red-900 rounded-md"
          v-if="error"
        >
          ❌ Invalid username or password
        </div>
        <form action="" @submit.prevent="submitLogin">
          <div class="mx-auto max-w-md">
            <div class="mt-8 input-container">
              <img src="../assets/icon-user.svg" />
              <input
                v-model="username"
                type="email"
                placeholder="your@email.com"
                class="pt-2 pb-2 text-lg placeholder-gray-300 bg-gray-900 rounded-full outline-none"
                required
              />
            </div>
            <div class="mt-4 input-container">
              <img src="../assets/icon-password.svg" />
              <input
                v-model="password"
                type="password"
                placeholder="password"
                class="pt-2 pb-2 text-lg placeholder-gray-300 bg-gray-900 rounded-full outline-none"
                required
              />
            </div>
            <input
              type="submit"
              :value="busy ? 'Please wait..' : 'Sign In'"
              :disabled="busy"
              class="pt-2 pb-2 mt-6 w-full text-lg font-medium text-gray-800 bg-gray-200 rounded-full cursor-pointer disabled:bg-gray-600"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
const users = [
  { u: "615037389", p: "1633476506" }, // st
  { u: "304705762", p: "-857185028" }, // prcl
  { u: "1700459975", p: "-1943258653" }, // stratum
  { u: "-351214274", p: "-305855062" }, // zs
];

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      busy: false,
      error: false,
      success: false,
    };
  },
  mounted: function() {
    this.$analytics.logEvent("view-login");
    this.loadJS("https://cdn.tryretool.com/embed.js");
  },
  methods: {
    submitLogin() {
      const vm = this;
      const delay = 1500 + Math.random() * 500; // msec

      vm.busy = true;
      window.setTimeout(() => {
        if (vm.verifyLogin(vm.username, vm.password)) {
          this.$analytics.logEvent("login-success");
          vm.error = false;

          vm.success = true; // show IFRAME
          vm.$nextTick(() => {
            window.retool.RetoolDashboard(
              vm.$refs.retool,
              "https://sijilat.retool.com/embedded/public/cbbe4ee9-1d60-4099-8f61-ce5d4fc6bcf3"
            );
          });
        } else {
          this.$analytics.logEvent("login-failed");
          vm.error = true;
        }

        vm.busy = false; // done
      }, delay);
    },

    verifyLogin(u, p) {
      const match = users.filter(
        (pair) =>
          this.hashString(u) == pair["u"] && this.hashString(p) == pair["p"]
      ).length;
      return !!match;
    },

    hashString(str) {
      var hash = 0;
      if (str.length == 0) {
        return hash;
      }
      for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      return `${hash}`;
    },

    loadJS(url) {
      return new Promise((resolve) => {
        let scr = document.createElement("script");
        scr.src = url;
        scr.async = false;
        scr.addEventListener("load", () => resolve(true));

        const head = document.head || document.getElementsByTagName("head")[0];
        head.appendChild(scr);
      });
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.hero-clip {
  clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
  background: rgba(29, 30, 43, 0.96);
  max-width: 770px;
  width: 60vw;
}

.input-container {
  position: relative;

  img {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  input {
    padding-left: 40px;
  }
}
</style>
