import Amplitude from "amplitude-js";

export default {
  install(Vue, options) {
    // Our proxy to the amplitude API endpoint
    const apiEndpoint = "t992x85dfe.execute-api.me-south-1.amazonaws.com/prod";

    Amplitude.getInstance().init(options.apiKey, null, {
      apiEndpoint: apiEndpoint,
      includeReferrer: true,
      includeUtm: true,
      forceHttps: true
    });

    Vue.prototype.$analytics = Vue.analytics = Amplitude.getInstance();
  }
};

// Now you can log anywhere in Vue using:
// this.$analytics.logEvent('complete');
