import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(VueAxios, axios)

import Main from '../views/Main'
import Results from '../views/Results'
import Login from '../views/Login'
import Register from '../views/Register'
import PrivacyPolicy from '../views/PrivacyPolicy'
import TermsOfUse from '../views/TermsOfUse'

  const routes = [
    {
      name: 'root',
      path: '/',
      component: Main,
    },
    {
      name: 'results',
      path: '/results',
      component: Results,
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
    },
    {
      name: 'register',
      path: '/register',
      component: Register,
    },
    {
      name: 'privacy',
      path: '/privacy',
      component: PrivacyPolicy,
    },
    {
      name: 'terms-of-use',
      path: '/terms-of-use',
      component: TermsOfUse,
    },
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
