<template>
  <div>
    <div class="hero">
      <div class="hero-clip md:pl-12 md:pr-12">
        <div class="pt-4 pb-4 md:pt-10 md:pb-10 flex flex-row align-middle">
          <div class="mr-10 ml-4">
            <a href="#">
              <img src="../assets/logo.svg" alt="Sijilat - Logo" />
            </a>
          </div>
          <div class="text-lg text-gray-600 mr-8">
            <router-link :to="{ name: 'root' }">&larr; Back</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="pl-4 pr-4 lg:pl-12 lg:pr-12 mt-10">
      <h1 class="text-gray-100 text-4xl font-extrabold leading-tight">Privacy Policy</h1>
      <p
        class="text-lg text-gray-300 pt-6"
      >PLEASE READ THE FOLLOWING THE PRIVACY POLICY CAREFULLY BEFORE ACCESSING AND USING THE SIJILAT SERVICE WHICH PROVIDES USERS (HEREINAFTER “END-USER”, “USER”, “YOU”, “YOUR”, “THEY”, “THEIR”) A SIMPLE INTERFACE FOR ACCESSING BUSINESS REGISRY INFORMATION BY INTEGRATING DIRECTLY WITH THE BACK-END SYSTEMS OF VARIOUS THIRD PARTY SERVICES INCLUDING PUBLIC GOVERNMENT SERVICES OFFERED BY THE KINGDOM OF BAHRAIN (“SERVICES”). SIJILAT INC IS NOT A GOVERNMENT SERVICE (HEREINAFTER “SIJILAT”, “WE”, “OUR”, “US”) AND IS NOT AFFILIATED WITH ANY GOVERNMENT SERVICE. THE PRIVACY POLICIES SET FORTH IN THIS DOCUMENT GOVERN HOW SIJILAT USES, TRANSMITS AND STORES INFORMATION AND DATA.</p>
      <p
        class="text-lg text-gray-300 pt-6"
      >SIJILAT MAY MODIFY THIS PRIVACY POLICY AT ANY TIME EFFECTIVE UPON ITS POSTING. YOUR CONTINUED USE OF OUR SERVICES CONSTITUTES YOUR ACCEPTANCE OF THIS PRIVACY POLICY AND ANY UPDATES. YOUR USE OF OUR SERVICES IS SUBJECT TO THE END USER LICENSE AGREEMENT LOCATED AT OUR WEBSITE. IF YOU HAVE ANY QUESTIONS ABOUT OUR PRIVACY POLICY, PLEASE EMAIL US AT SALES@SIJILAT.IO</p>
      <p
        class="text-lg text-gray-300 pt-6"
      >BY USING OUR SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY ALL OF THE TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE OR YOU ARE LEGALLY NOT CAPABLE TO CONSENT TO THESE TERMS, DO NOT ACCESS OR USE OUR SERVICES.</p>

      <p class="text-lg text-gray-300 pt-6 text-center">· · ·</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >We collect information relating to your identity (“Personal Information”) such as your name. When using our Service, information about you. Sijilat, as the data controller, will store and process data provided by the User in accordance with the Kingdom of Bahrain's Data Protection Law and other relevant legislations and use the data only for the purposes and in the manner as set forth herein. Such data includes your IP-address, as well as what kind of device and operating system you use and above-mentioned Information which are collected and processed for the purposes of conducting and carrying out a secure Data Transfer, as well as to meet legal requirements within the field of data storage requirements and other relevant legal obligations of Sijilat, including but not limited to, for the purpose of preventing fraud and other criminal acts.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat, as the data manager shall apply technical and organizational measures capable of protecting the data against unintentional or unauthorized destruction, accidental loss, unauthorized alteration, disclosure or access, or any other form of processing.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat does not knowingly collect Personal Information from minors under the age of 18. Minors are not permitted to use the Services and Sijilat requests that minors under the age of 18 not to submit any Personal Information. Since information regarding minors under the age of 18 is not collected, Sijilat does not knowingly distribute personal Information regarding minors under the age of 18.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat currently does not store, process or transfer Personal Information and/or data of EU and EEA residents.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >We use the data and Personal Information you provide to us primarily for the purpose of authenticating the identity of the User and/or for the purpose of executing the Data Transfer and to meet legal requirements.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat does not sell or license any Personal Information that it may collect from you from using our Services, nor does Sijilat use your data for marketing purposes. Sijilat may transfer or otherwise disclose your Personal Information and data to its employees, agents and consultants if the disclosure will enable these parties to perform business, professional or technical support functions for Sijilat. Such parties will be subject to the same obligations as Sijilat under this Policy. Sijilat may disclose your Personal Information to a government institution that has asserted its lawful authority to obtain the information or where Sijilat has reasonable grounds to believe the information could be useful in the investigation of unlawful activity, or to comply with a subpoena or warrant or an order made by a court, person or body with jurisdiction to compel the production of information, or to comply with court rules regarding the production of records and information, or to protect Sijilat’s rights and property. Sijilat may be involved in the sale or transfer of some or all of its assets or other transactions. As part of that transaction, Sijilat may disclose your Personal Information to the acquiring organization or other organization involved in the transaction, to the extent permitted by law, but will require such organization to agree to protect the privacy of your Personal Information in a manner that is consistent with this Policy and applicable law.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat shall treat all collected and stored personal data and Personal Information with confidentiality to the best of its abilities and will apply SSL and secure encryption procedures. Your Personal Information and data shall only be stored in Sijilat’s secured servers and not in external cloud-based data storage services such as iCloud, Drop Box, or Google Drive. The duration for which we store your data may vary but it will be no longer than 10 years following the discontinuation in your use of our services.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Depending on the data that we process in the particular case, we rely on different legal grounds, the primary ones are that we have legal obligations and that we have a legitimate interest of processing your data.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat is not responsible for the privacy policies of third-party websites, go vernment service providers, apps, APIs, etc. If you provide any information to such third parties, different rules regarding the collection and use of your personal information may apply. We strongly suggest you review such third party’s privacy policies before providing any data to them. We are not responsible for the policies or practices of third parties. Please be aware that our websites may contain links to other sites on the Internet that are owned and operated by third parties. The information practices of those websites linked to our site is not covered by this Policy. These other sites may send their own cookies or clear GIFs to users, collect data or solicit personally identifiable information. We cannot control this collection of information. You should contact these entities directly if you have any questions about their use of the information that they collect.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >You have several rights when it comes to understanding what we do with your personal data and Personal Information and can also, for instance, request us to correct data about you should it be incorrect. You may also ask us to block and/or erase your data in accordance with the DPL. However, any request that would refrain Sijilat from adhering to any Know Your Customer Rules or any other legislations shall be denied.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >We have a Support team and a Data Protection Supervisor that you can contact if you have questions about this Privacy Policy and our handling of your personal data and Personal Information.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Please contact our Data Protection Supervisor at sales@sijilat.io.</p>

      <p
        class="text-lg text-gray-300 pt-6 pb-32"
      >Please check the Privacy Policy every time you use our Service, as updates may include information on additional processing activities we intend to perform going forward.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  mounted() {
    window.setTimeout(() => window.scrollTo(0, 0), 1);
    this.$analytics.logEvent('view-privacy-policy')
  },
};
</script>

<style lang="scss" scoped>
.hero-clip {
  clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
  background: rgba(29, 30, 43, 0.96);
  max-width: 770px;
}
</style>