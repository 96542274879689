<template>
  <div class="bg-gray-900">
    <div class="hero">
      <div class="hero-clip pl-4 pr-4 md:pl-12 md:pr-12">
        <div class="pt-10 grid md:flex md:flex-row gap-4">
          <div class="col-span-5 md:mr-10">
            <a href="#">
              <img src="../assets/logo.svg" alt="Sijilat - Logo" />
            </a>
          </div>
          <div class="text-lg text-gray-600 md:mr-8">
            <a href="#pricing">Pricing</a>
          </div>
          <div class="text-lg text-gray-600 md:mr-8">
            <a href="#features">Features</a>
          </div>
          <div class="text-lg text-gray-600 md:mr-8">
            <router-link :to="{ name: 'login' }">Login</router-link>
          </div>
          <div class="text-lg text-gray-600">
            <a href="mailto:sales@sijilat.io">Contact</a>
          </div>
        </div>

        <div class="mt-20 text-gray-800 text-5xl font-bold leading-tight">
          Analyze all
          <span class="text-purple-500">
            <span id="count-up">1</span>*
          </span>
          <br />Bahraini businesses
        </div>

        <div
          class="text-gray-400 mt-5 leading-normal max-w-md text-lg"
        >Sijilat is a real-time API built on Bahrain’s business directory providing robust business insights.</div>

        <form v-on:submit.prevent="onSearch">
          <div class="mt-10 md:flex flex-row align-middle">
            <div class="search-container md:mr-4">
              <input
                v-model="search"
                type="text"
                placeholder="Company, Owner, CR#, .."
                class="rounded-full pt-2 pb-2 text-lg outline-none placeholder-gray-300 border-4 border-transparent focus:border-purple-500 transition-all duration-150 w-full md:w-auto"
                autocorrect="off" autocomplete="off" spellcheck="false"
                required
              />
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0306 10.8995L15 15M6.6136 1C9.71391 1 12.2272 3.5963 12.2272 6.799C12.2272 10.0017 9.71391 12.598 6.6136 12.598C3.5133 12.598 1 10.0017 1 6.799C1 3.5963 3.51329 1 6.6136 1Z" stroke="#A4A5B2" stroke-width="2"/>
              </svg>
            </div>
            <input
              type="submit"
              value="Basic Search"
              class="rounded-full text-lg bg-gray-200 text-gray-800 pt-3 pb-3 md:pt-2 md:pb-2 pl-6 pr-6 font-medium cursor-pointer transition-all duration-200 hover:bg-gray-300 hover:text-gray-700 mt-4 md:mt-0"
            />
          </div>
        </form>

        <div class="mt-24 pb-6 text-gray-300">* Updated 2 hours ago</div>
      </div>
    </div>

    <div class="text-center max-w-3xl md:m-auto pt-32 ml-4 mr-4">
      <div id="features"></div>
      <h2 class="text-4xl leading-tight font-extrabold text-gray-100">Solutions for your industry</h2>
      <div
        class="text-xl text-gray-300 mt-4"
      >The Sijilat API provides solutions for diverse industries, addressing business intelligence requirements across the board.</div>
    </div>

    <div class="md:flex flex-row mt-40 ml-4 mr-4 md:pl-12 md:pr-12 justify-between items-center relative">
      <div class="md:w-1/2 z-10 relative md:order-2">
        <img src="../assets/waves.svg" class="absolute" style="top: -40px; right: -40px; z-index: -1;" />

        <img
          src="../assets/screen-marketing.svg"
          class="m-auto text-center md:transform md:scale-125"
        />
      </div>

      <div class="md:w-1/2 md:order-1 mt-6 md:mt-0">
        <h3 class="text-4xl leading-tight font-extrabold text-gray-100">Precision marketing, check</h3>
        <div
          class="leading-normal text-gray-300 text-lg mt-2"
        >With Sijilat’s Advanced Filters, you can target the right customer, in the right industry, at the right time.</div>

        <div class="mt-10 flex flex-row items-start">
          <div class="flex-none">
            <img src="../assets/icon-bg-filter.svg" />
          </div>
          <div class="ml-3">
            <div class="font-bold text-xl text-gray-100">Advanced filters, precise targetting</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Target business owners using fine-tuned filters (“new single-branch restaurants in Seef”)</div>
          </div>
        </div>

        <div class="mt-10 flex flex-row items-start">
          <div class="flex-none">
            <img src="../assets/icon-bg-integrate.svg" />
          </div>
          <div class="ml-3">
            <div class="font-bold text-xl text-gray-100">Integrates directly with your CRM</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Integrates with your CRM, and email marketing tool of choice (Mailchimp, ConvertKit, etc)</div>
          </div>
        </div>
      </div>
    </div>

    <div class="md:flex flex-row mt-40 pl-4 pr-4 md:pl-12 md:pr-12 justify-between items-center">
      <div class="mb-10 md:mb-0 md:w-1/2 z-10 relative">
        <img src="../assets/waves.svg" class="absolute" style="top: -40px; left: -40px; z-index: -1;" />

        <img
          src="../assets/screen-charts.svg"
          class="m-auto text-center md:transform md:scale-125"
        />
      </div>

      <div class="md:w-1/2 md:pr-16">
        <h3
          class="text-4xl leading-tight font-extrabold text-gray-100"
        >Make investing decisions on timely, precise data</h3>
        <div
          class="leading-normal text-gray-300 text-lg mt-2"
        >For investors looking for that extra edge, Sijilat provides you unparalleled visibility into the inner workings on the Bahraini economy.</div>

        <div class="mt-10 flex flex-row items-start">
          <div class="flex-none">
            <img src="../assets/icon-bg-metrics.svg" />
          </div>
          <div class="ml-3">
            <div class="font-bold text-xl text-gray-100">Business metrics, across every industry</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Track business metrics for companies in every sector of the Bahraini economy, in real-time</div>
          </div>
        </div>

        <div class="mt-10 flex flex-row items-start">
          <div class="flex-none">
            <img src="../assets/icon-bg-time.svg" />
          </div>
          <div class="ml-3">
            <div class="font-bold text-xl text-gray-100">Real-time data, straight from the source</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Beat the competition. Query historical data, business registration growth and the fastest-growing local companies.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="md:flex flex-row mt-40 pl-4 pr-4 md:pl-12 md:pr-12 justify-between items-center relative">
      <div class="md:w-1/2 z-10 mb-16 ml-2 mr-2 md:m-0 relative md:order-2">
        <img src="../assets/waves.svg" class="absolute" style="top: -40px; right: -40px;" />

        <img
          src="../assets/screen-app.svg"
          class="m-auto text-center"
          style="transform: scale(1.1);"
        />
      </div>

      <div class="md:w-1/2 md:order-1">
        <h3
          class="text-4xl leading-tight font-extrabold text-gray-100"
        >Financial intelligence, automated.</h3>
        <div
          class="leading-normal text-gray-300 text-lg mt-2"
        >Get near real-time notifications of business closure, violations, mortgages, and sequesters.</div>

        <div class="mt-10 flex flex-row items-start">
          <div class="flex-none">
            <img src="../assets/icon-bg-indirect.svg" />
          </div>
          <div class="ml-3">
            <div class="font-bold text-xl text-gray-100">Fast-track signups</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Users just need to provide a company name or number. Retrieve all the other details straight from Sijilat.</div>
          </div>
        </div>

        <div class="mt-10 flex flex-row items-start">
          <div class="flex-none">
            <img src="../assets/icon-bg-integrate.svg" />
          </div>
          <div class="ml-3">
            <div class="font-bold text-xl text-gray-100">Works out of the box</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Integrates with everything from your CRM to your Core Banking module of choice</div>
          </div>
        </div>
      </div>
    </div>

    <div class="waves md:flex flex-row mt-40 pl-4 pr-4 md:pl-12 md:pr-12 justify-between items-start relative">
      <div class="md:w-1/3">
        <h3 class="text-4xl font-extrabold text-gray-100">Real-time API</h3>
      </div>

      <div class="md:w-1/3 md:pr-4">
        <div class="mt-16 md:mt-0">
          <img src="../assets/icon-bg-integrate.svg" />
          <div class="mt-4">
            <div class="font-bold text-xl text-gray-100">Streamlined user onboarding</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Through real-time validation of commercial registration numbers</div>
          </div>
        </div>

        <div class="mt-16">
          <img src="../assets/icon-bg-code.svg" />
          <div class="mt-4">
            <div class="font-bold text-xl text-gray-100">RESTful JSON API</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Native RESTful JSON API, only one of it’s kind in the Bahrain market</div>
          </div>
        </div>
      </div>

      <div class="md:w-1/3 md:pr-16">
        <div class="mt-16 md:mt-0">
          <img src="../assets/icon-bg-add.svg" />
          <div class="mt-4">
            <div class="font-bold text-xl text-gray-100">Instant Data Enrichment</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Extract owner and shareholder details using just a CR number</div>
          </div>
        </div>

        <div class="mt-16">
          <img src="../assets/icon-bg-search.svg" />
          <div class="mt-4">
            <div class="font-bold text-xl text-gray-100">Partial & fuzzy search</div>
            <div
              class="leading-normal text-gray-300 mt-1"
            >Search by partial names or choose from 25+ advanced query filters</div>
          </div>
        </div>
      </div>
    </div>

    <div id="pricing"></div>
    <!-- relative/absolute dance below so we can overlap w/footer -->
    <div class="md:relative md:mt-32 md:h-55">
      <div class="md:absolute md:pl-12 md:pr-12 md:z-10 w-full">
        <div class="relative md:flex md:flex-row md:justify-center md:items-center">
          <div class="tier">
            <div class="text-3xl font-bold text-gray-100 text-center mt-5">Startup</div>
            <div class="flex flex-row items-end justify-center mt-6">
              <div class="text-4xl leading-none font-extrabold text-gray-100 text-center">$79</div>
              <div class="text-lg text-gray-300">/month</div>
            </div>
            <div class="mt-6 bg-gray-900 border-gray-700 border-t pl-4 pr-4 md:pl-6 md:pr-6">
              <div class="mt-6 text-gray-300 text-lg">
                <img src="../assets/icon-tick.svg" class="inline-block mr-2" /> Up to 20 queries/day
              </div>
              <div class="mt-6 text-gray-300 text-lg">
                <img src="../assets/icon-tick.svg" class="inline-block mr-2" /> Advanced search filters
              </div>
              <div class="mt-8 pb-6">
                <input
                  type="button"
                  value="Order Now"
                  class="block rounded-full pt-2 pb-2 w-full shadow-md border-0 text-purple-500 text-lg cursor-pointer bg-white"
                  data-stripe-price="price_1HE93tAho2MFHXFHtEKtlke0"
                  v-on:click="processPayment"
                />
                <div id="error-message-price_1HE93tAho2MFHXFHtEKtlke0"></div>
              </div>
            </div>
          </div>

          <div class="tier popular z-20 border-purple-500 border">
            <div class="md:pl-4 md:pr-4 md:pt-3 md:pb-2">
              <!-- decor -->
              <div class="text-3xl font-bold text-gray-100 text-center mt-5">Small Business</div>
              <div class="flex flex-row items-end justify-center mt-6">
                <div class="text-4xl leading-none font-extrabold text-gray-100 text-center">$299</div>
                <div class="text-lg text-gray-300">/month</div>
              </div>
            </div>
            <div class="mt-6 bg-gray-900 border-gray-700 border-t pl-4 pr-4 md:pl-6 md:pr-6">
              <div class="md:pl-4 md:pr-4 md:pt-3 md:pb-2">
                <!-- decor -->
                <div class="mt-6 text-gray-300 text-lg">Everything in the Startup Plan, plus:</div>
                <div class="mt-6 text-gray-300 text-lg">
                  <img src="../assets/icon-tick.svg" class="inline-block mr-2" /> Unlimited Search queries
                </div>
                <div class="mt-6 text-gray-300 text-lg">
                  <img src="../assets/icon-tick.svg" class="inline-block mr-2" /> All Marketing integrations
                </div>
                <div class="mt-6 text-gray-300 text-lg">
                  <img src="../assets/icon-tick.svg" class="inline-block mr-2" /> Export results in CSV, Excel
                </div>
                <div class="mt-8 pb-6">
                  <input
                    type="button"
                    value="Order Now"
                    class="block rounded-full pt-2 pb-2 w-full shadow-md border-0 text-white text-lg cursor-pointer bg-purple-500 hover:bg-purple-600 transition-all duration-150"
                    data-stripe-price="price_1HE94MAho2MFHXFHPJpmRii0"
                    v-on:click="processPayment"
                  />
                  <div id="error-message-price_1HE94MAho2MFHXFHPJpmRii0"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="tier">
            <div class="text-3xl font-bold text-gray-100 text-center mt-5">Enterprise</div>
            <div class="mt-2 text-lg text-gray-300 text-center">Custom Pricing</div>
            <div class="mt-6 bg-gray-900 border-gray-700 border-t pl-4 pr-4 md:pl-6 md:pr-6">
              <div class="mt-6 text-gray-300 text-lg">Everything else, plus:</div>
              <div class="mt-6 text-gray-300 text-lg">
                <img src="../assets/icon-tick.svg" class="inline-block mr-2" /> Priority Support
              </div>
              <div class="mt-6 text-gray-300 text-lg">
                <img src="../assets/icon-tick.svg" class="inline-block mr-2" /> Salesforce Integration
              </div>
              <div class="mt-8 pb-6">
                <input
                  type="button"
                  value="Request a Demo"
                  class="block rounded-full pt-2 pb-2 w-full shadow-md border-0 text-purple-500 text-lg cursor-pointer bg-white"
                  onclick="window.location.href='mailto:sales@sijilat.io?subject=Enterprise%20Plan&body=Hello%2C%20I\'m%20interested%20in%20signing%20up%20to%20the%20Enterprise%20Plan%20with%20Sijilat.io.%20(Include%20description%20of%20your%20use-case%20here)'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- faux footer -->
    <div class="bg-gray-100 pt-64 hidden md:block"></div>

    <Footer></Footer>
  </div>
</template>

<script>
import { CountUp } from 'countup.js';
import Footer from '../components/footer';

export default {
  name: "Main",
  metaInfo: {
    title: "Sijilat.io | Business Intelligence API for Bahrain",
    meta: [
      {
        name: "description",
        content:
          "Sijilat is the de facto business intelligence API for Bahrain",
      },
      {
        property: "og:title",
        content: "Sijilat.io | Business Intelligence API for Bahrain",
      },
    ],
  },
  data() {
    return {
      search: ""
    };
  },
  mounted() {
    this.loadStripe()
    this.initCountUp()
    this.$analytics.logEvent('view-main')
  },
  methods: {
    loadStripe: function() {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3";
      script.async = false;

      let head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(script);
    },
    initCountUp: function() {
      const counter = new CountUp('count-up', 271829)
      counter.start()
    },
    onSearch: function() {
      this.$router.push({ name: 'results', query: { q: this.search } })
    },
    processPayment: function (event) {
      var stripe = window.Stripe("pk_live_TDJ2ughPKFqPrJlDAiS1NTL5");
      const price = event.target.dataset["stripePrice"];

      this.$analytics.logEvent('payment-attempt', { price })

      stripe
        .redirectToCheckout({
          lineItems: [{ price, quantity: 1 }],
          mode: "subscription",
          successUrl: "https://sijilat.io/register#success",
          cancelUrl: "https://sijilat.io/#pricing",
        })
        .then(function (result) {
          if (result.error) {
            var displayError = document.getElementById(
              "error-message-" + price
            );
            displayError.textContent = result.error.message;
          }
        });
    },
  },
  components: {
    Footer
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("../assets/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-clip {
  background: rgba(29, 30, 43, 0.96);
  background-blend-mode: multiply;
  max-width: 770px;
}
@screen md {
  .hero-clip {
    clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
  }
}

.search-container {
  @apply relative;
  img, svg {
    position: absolute;
    top: 17px;
    left: 15px;
  }

  input:focus + svg > path {
    stroke: theme('colors.purple.500') !important;
  }

  input {
    padding-left: 40px;
  }
}

.waves {
  background: url(../assets/waves.svg) top left no-repeat;
}

.tier {
  @apply overflow-hidden flex-grow z-10 rounded-lg bg-white mt-8 ml-4 mr-4 shadow-md;

  &:first-of-type {
    @apply mt-16;
  }

  &:last-of-type {
    @apply mb-16;
  }
}

@screen md {
  .tier {
    @apply shadow-lg border-0;
  }

  /* have to apply m-0 individually to increase specificity */
  .tier:nth-child(1) {
    @apply transform translate-x-2 m-0;
    max-width: 320px;
  }
  .tier:nth-child(2) {
    @apply m-0;
    max-width: 400px;
  }
  .tier:nth-child(3) {
    @apply transform -translate-x-2 m-0;
    max-width: 320px;
  }
}

@screen md {
  .popular::before {
    content: "MOST POPULAR";
    @apply absolute bg-purple-500 text-lg text-white capitalize pt-1 pb-1 pl-4 pr-4 rounded-full;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>