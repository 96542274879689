<template>
  <div>
    <div class="hero">
      <div class="hero-clip md:pl-12 md:pr-12">
        <div class="pt-4 pb-4 md:pt-10 md:pb-10 flex flex-row align-middle">
          <div class="mr-10 ml-4">
            <a href="#">
              <img src="../assets/logo.svg" alt="Sijilat - Logo" />
            </a>
          </div>
          <div class="text-lg text-gray-600 mr-8">
            <router-link :to="{ name: 'root' }">&larr; Back</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="pl-4 pr-4 lg:pl-12 lg:pr-12 mt-10">
      <h1 class="text-gray-100 text-4xl font-extrabold leading-tight">Terms of Use</h1>

      <p
        class="text-lg text-gray-300 pt-6"
      >PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING AND USING SIJILAT (MAY ALSO BE REFERRED AS “SOFTWARE”) SERVICE WHICH PROVIDES PROVIDES USERS (HEREINAFTER “END-USER”, “USER”, “YOU”, “YOUR”, “THEY”, “THEIR”) A SIMPLE INTERFACE FOR ACCESSING BUSINESS REGISRY INFORMATION BY INTEGRATING DIRECTLY WITH THE BACK-END SYSTEMS OF VARIOUS THIRD PARTY SERVICES INCLUDING PUBLIC GOVERNMENT SERVICES OFFERED BY THE KINGDOM OF BAHRAIN (“SERVICES”). SIJILAT INC IS NOT A GOVERNMENT SERVICE (HEREINAFTER “SIJILAT”, “WE”, “OUR”, “US”) AND IS NOT AFFILIATED WITH ANY GOVERNMENT SERVICE. THE TERMS AND CONDITIONS SET FORTH IN THIS END USER LICENSE AGREEMENT GOVERN YOUR USE OF THE SERVICES OFFERED BY SIJILAT.</p>

      <p class="text-lg text-gray-300 pt-6 text-center">· · ·</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Please note that the Sijilat Privacy Policy and Sijilat Terms and Conditions are incorporated by reference and are binding parts of the Terms of Use (collectively "Terms"). The Terms constitute the entire agreement and understanding regarding the access to and use of the Service and supersede all prior communications, proposals, representations, agreements and understandings, whether written or oral, relating to its subject matter. The Services are available for your use only on the condition that you agree to the Terms, all with the intention to form a legally binding agreement. By clicking “Accept”, accessing or using the Services, you have indicated that you are at least 18 years of age or older, and understand and accept these Terms. If you are an employee, consultant or contractor using the Services on behalf of or as part of your work ("Authorized User") for a company or other entity ("Company"), you represent that you have the authority to bind that Company to these Terms and that you are authorized by your Company to use the Services. If you do not accept these Terms, please discontinue to use the Service and do not proceed with any transaction. By using the Service, you agree to the Terms as set forth in this Agreement at the time of the Data Transfer. The Terms may be revised from time to time without prior notice and the User is therefore advised to review the Terms in each case before proceeding with a Transfer. In the event of change of the Terms, the revised version shall be published on the Sijilat Website. Legally binding regulatory or legal decisions and/or changes made by competent authorities may affect Sijilat’s business and its provision of Services. The User hereby accepts that Sijilat has no control over such decisions and/or changes and therefore assumes no liability for any sort of losses, damages or harm that may arise as a result.</p>

      <p class="text-lg text-gray-300 pt-6">THE SERVICES</p>

      <p class="text-lg text-gray-300 pt-6">
        Sijilat can modify, update, suspend, or stop the provision of Services when it encounters malicious attacks or in order to meet technical requirements and to comply with legal and regulatory changes.
        INFORMATION AND DATA PROVIDED BY THE USER
      </p>

      <p
        class="text-lg text-gray-300 pt-6"
      >By using the Service, the User agrees that the entirety of the information about the User available on the Sijilat Interface, including but not limited to name, address, telephone number, e-mail address (collectively “Information”) shall be utilized by Sijilat for the purpose of authenticating the identity of the User and/or for the purpose of executing the Data Transfer. By submitting all the required Information on the Sijilat Interface, you are deemed to have expressly requested and authorized the Data Transfer to take place in accordance with Section 2. In the event that you decide not to complete the Data Transfer and do not click on the Search button, your Information is purged from Sijilat’s system and Sijilat never receives the purged Information and/data.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Any Data Transfer you make through the use of your Information shall be deemed to be carried out by the authorized owner of the account. Any and all Information provided by you during the Transfer process will be collected, stored and processed by Sijilat in connection with using the Service. Sijilat’s processing of such Information takes place in accordance with the Sijilat Privacy Policy.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat, as the data controller, will store and process data provided by the User in accordance with the Kingdom of Bahrain's Personal Data Protection Law and other relevant Bahraini legislations and use the data only for the purposes and in the manner as set forth herein. Such data includes the IP-address of the User and above-mentioned Information which are collected and processed for the purposes of conducting and carrying out a secure Transfer, as well as to meet legal requirements within the field of data storage requirements, anti-money laundering measures and other relevant legal obligations of Sijilat, including but not limited to, for the purpose of preventing fraud and other criminal acts.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat, if requested by law enforcement and other relevant authorities, may hand over Information on the User in connection with investigations of criminal acts. Otherwise, Sijilat will undertake appropriate measures to protect the data from being released to third parties and undertakes share any such data for commercial, advertising or similar purposes.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat will treat all collected and stored personal data with confidentiality to the best of its abilities and will apply SSL and secure encryption procedures.</p>

      <p class="text-lg text-gray-300 pt-6">
        Sijilat stores all user information in secure databases protected in a variety of industry-standard access controls. However, no company can fully eliminate security risks associated with the transmission of personal information through online transactions, and You do so at Your own risk. You acknowledge that any data entry, conversion or storage is subject to the likelihood of human and machine errors, malicious manipulation, omissions, delays, and losses, including, but not limited to, inadvertent loss of: data or damage to media that may result in loss or damage to You and/or Your property, and/or Your detrimental reliance on maliciously manipulated data. Sijilat shall not be liable for any such errors, omissions, delays, or losses. You are responsible for adopting reasonable measures to limit the impact of such problems, including backing up data, adopting procedures to ensure the accuracy of input data, examining and confirming results prior to use, adopting procedures to identify and correct errors and omissions, replacing lost or damaged media, and reconstructing data. You are also responsible for complying with all local, state, and federal laws pertaining to the use and disclosure of any data.
        GRANT OF LICENSE
      </p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Sijilat grants you a non-exclusive, worldwide, revocable, non-transferable and non-sublicensable license to access and use the Services, for the duration of your use of the Services.</p>

      <p class="text-lg text-gray-300 pt-6">CONFIDENTIALITY</p>

      <p class="text-lg text-gray-300 pt-6">
        You agree that the Software, including, but not limited to, all source and object code components, screen shots and displays, graphical user interfaces, algorithms, formulae, data structures, scripts, application programming interfaces and protocols, and the Documentation (collectively the "Confidential Information") are trade secrets of Sijilat and are owned by Sijilat or, where applicable, its third-party licensors. You agree to retain all Confidential Information in strict confidence at least with the same amount of diligence that You exercise in preserving the secrecy of Your most-valuable information, but in no event less than reasonable diligence. You agree to: (i) only disclose Confidential Information to Your employees and agents to the extent required to use the Software under the terms of this Agreement and not to disclose or disseminate the Confidential Information to any third party without the prior written consent of Sijilat, (ii) use the Confidential Information solely for Your benefit as provided for herein and not to allow any third party to benefit from the Confidential Information, and (iii) bind Your employees and agents, by terms no less restrictive than those set forth herein, to maintain the confidentiality of such Confidential Information, and not use or disclose such information except as permitted under this Agreement. Notwithstanding the disclosure of any Confidential Information for any reason, such Confidential Information shall continue to be owned by Sijilat or its licensors. Nothing contained herein shall be deemed to prevent You from disclosing or disseminating Your data, in any format or any report, to whomever You so choose.
        ACCEPTABLE USE
      </p>

      <p class="text-lg text-gray-300 pt-6">By accessing or using the Services, you agree to:</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Use commercially reasonable efforts to prevent unauthorized access to or use of the Services, including maintaining adequate security precautions, consistent with then current standards in the industry, the use of secure servers, protective firewalls and a user authentication system and notify us of any such unauthorized access or use promptly upon becoming aware of such access or use;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not use the Services to engage in illegal, fraudulent or other wrongful conduct, including, but not limited to violation of any applicable local, state, national or international law, rule, regulation or order of any court;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not transfer, distribute, sell, resell, lease, sublease, license, or sub-license access to the Services or any component thereof;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not create, modify, distribute, transmit, display, or perform derivative works based on the Services or any components thereof;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not copy, frame or mirror any part or content of the Services or any components thereof;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not disassemble, decompile or otherwise reverse engineer the Services;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not use any robot, spider, search/retrieval application, or other manual process or automatic device or application or extraction tool to access, monitor, copy, use, download, retrieve, index, extract, scrape, data mine, interact, overload, or in any way reproduce or circumvent the navigational structure or presentation of the Service or any component thereof or otherwise defeat, bypass, or circumvent any other protections of Sijilat’s proprietary rights in the Service;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not delete or alter Sijilat’s copyright, trademark or other proprietary notices, branding or attribution included in the Service;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not use the Services to store or transmit viruses, worms, time bombs, Trojan horses and other harmful or malicious code, files, scripts, agents or programs or other harmful, contaminating or destructive feature;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not intentionally interfere with or disrupt the integrity or performance of the Services or third-party data or services contained therein, by means of "hacking" or defacing any portion of the Services or otherwise impairing or overburdening them;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not attempt to gain unauthorized access to the Services or their related systems or networks;</p>

      <p class="text-lg text-gray-300 pt-6">Not enable others to violate any of these Terms;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not make the functionality of the Services available to multiple users through any means, including, without limitation, through any hosting, application services provider, service bureau, outsourced or value-added basis or any other type of services;</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Not access the Services for the purposes of monitoring their availability, performance or functionality, or for any benchmarking against third-party services or competitive purposes.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >In the event of a suspected criminal or illegal activity or suspected/detected misuse of the Services which might bring civil actions by third parties or expose Sijilat to any type of potential or actual harm, losses and damages, Sijilat shall have the unequivocal right to suspend, take appropriate measures and keep records of Data Transfers of the Users.</p>

      <p class="text-lg text-gray-300 pt-6">INTELLECTUAL PROPERTY</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >All right, title and interest, including all intellectual property in the Services or any part thereof are and shall remain the sole and exclusive property of Sijilat, and you are not by reason acquiring any rights therein, other than the limited rights expressly provided herein. No rights are granted to you hereunder other than as expressly set forth herein.</p>

      <p class="text-lg text-gray-300 pt-6">WARRANTIES AND DISCLAIMERS</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >SERVICES ARE PROVIDED “AS IS” WITHOUT ANY WARRANTY WHATSOEVER. SIJILAT DISCLAIMS ALL WARRANTIES WHETHER EXPRESS, IMPLIED, OR STATUTORY, TO MERCHANT AND END USERS AS TO ANY MATTER WHATSOEVER, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY SIJILAT OR ITS EMPLOYEES OR REPRESENTATIVES SHALL CREATE A WARRANTY OR IN ANY WAY INCREASE THE SCOPE OF SIJILAT’S OBLIGATIONS.</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >End User acknowledges that the Services are inclusive of a computer network-based service which may be subject to outages and delay occurrences. As such, Sijilat does not guarantee continuous or uninterrupted access to the Services. End User further acknowledges that access to the Sijilat Website or to the Services may be restricted for maintenance. Sijilat will make reasonable efforts to ensure that Data Transfers are processed in a timely manner; however, Sijilat will not be liable for any interruption, outage, or failure to provide the Services.</p>

      <p class="text-lg text-gray-300 pt-6">Limitation of Liability</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >IN NO UNFORESEEABLE EVENT AND/OR UNWILLFUL MISCONDUCT SHALL SIJILAT BE LIABLE HEREUNDER FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, LOST TIME OR GOODWILL, LOSS OF DATA OR BUSINESS INTERRUPTION/LOSSES OF ANY CHARACTER. UNDER ANY CIRCUMSTANCES, SIJILAT IS NOT LIABLE FOR ANY SORT OF BUSINESS LOSSES SUCH AS LOSS OF PROFITS, LOSS OF REVENUE, LOSS OF BUSINESS OPPORTUNITY, LOSS OF BUSINESS, BUSINESS INTERRUPTION, LOSS OF BUSINESS OPPORTUNITY OR LOSS OR DAMAGE TO REPUTATION. SIJILAT DOES NOT LIMIT ITS LIABILITY IN CASES WHERE IT IS UNLAWFUL TO DO SO ACCORDING TO THE LAWS OF THE KINGDOM OF BAHRAIN. IN NO EVENT WILL SIJILAT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY DAMAGES IN EXCESS OF THE ACTUAL AMOUNTS PAID BY YOU FOR USE OF THE SERVICES IN THE EVENT GIVING RISE TO ANY LIABILITY.</p>

      <p class="text-lg text-gray-300 pt-6">Indemnification</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >You will indemnify and hold harmless Sijilat and its officers, directors, employees and agents, providers from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (i) your access to or use of the Services, or (ii) your violation of these Terms.</p>

      <p class="text-lg text-gray-300 pt-6">Term and Termination</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >These Terms commence on the date that you first access or use the Services and will continue in effect until otherwise terminated as set forth below.</p>

      <p class="text-lg text-gray-300 pt-6">
        You can discontinue your use of the Services without any further notice. Sijilat may terminate these Terms immediately for any or no reason, without notice. Upon termination, you will cease your use of the Services.
        Surviving Provisions
      </p>

      <p
        class="text-lg text-gray-300 pt-6"
      >All sections of the Terms which by their nature survive termination shall continue in full force and effect beyond any termination of the Services and such Terms, including, without limitation, all provisions which relate to proprietary rights, disclaimers, limitation of liability, waiver and indemnification.</p>

      <p class="text-lg text-gray-300 pt-6">Relationship of the Parties.</p>

      <p class="text-lg text-gray-300 pt-6">
        Nothing in these Terms will be construed to create a partnership, joint venture or agency relationship between the parties.
        Force Majeure
      </p>

      <p
        class="text-lg text-gray-300 pt-6"
      >Neither Users nor Sijilat shall be liable to the other in any way for failure or delay in its performance under the Terms to the extent resulting from any cause or circumstance beyond its reasonable control, including the acts or omissions of the Sijilat and/or the User, act of God, fire, flood, or other natural disaster, malicious injury, strikes, lockouts, or other labor troubles, riots, insurrection, war or other reason of like nature not the fault of Sijilat and/or User in performing under the Terms.</p>

      <p class="text-lg text-gray-300 pt-6">SEVERABILITY</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >If any term, provision or part of this Agreement is to any extent held invalid, void or unenforceable by a court of competent jurisdiction, the remainder of this Agreement shall not be impaired or affected thereby, and each term, provision and part shall continue in full force and effect and shall be valid and enforceable to the fullest extent permitted by the applicable law.</p>

      <p class="text-lg text-gray-300 pt-6">GOVERNING LAW</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >The Terms and the Agreement shall be governed by the laws of the Courts in the Kingdom of Bahrain. Within 30 days of a User’s filing of complaint or claim, it is in Sijilat’s discretion to resort to mediation prior to litigation. Users and Sijilat jointly agree that all disputes relating to this Agreement shall be resolved exclusively in the Bahraini Courts.</p>

      <p class="text-lg text-gray-300 pt-6">WAIVER</p>

      <p
        class="text-lg text-gray-300 pt-6"
      >No term or provision of this Agreement shall be deemed waived, and no breach excused, unless such waiver or consent shall be in writing and signed by the party claimed to have waived or consented. Any consent by any party to, or waiver of, a breach by the other party, whether express or implied, shall not constitute a consent to, waiver of, or excuse for any different or subsequent breach.</p>

      <p
        class="text-lg text-gray-300 pt-6 pb-32"
      >If you have any questions about these Terms or the Services, please contact Sijilat at sales@sijilat.io.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  mounted() {
    window.setTimeout(() => window.scrollTo(0, 0), 1);
    this.$analytics.logEvent('view-terms-of-use')
  },
};
</script>

<style lang="scss" scoped>
.hero-clip {
  clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
  background: rgba(29, 30, 43, 0.96);
  max-width: 770px;
}
</style>