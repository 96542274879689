import Vue from 'vue'
import App from './App.vue'
import router from './router'
import analytics from "./utils/analytics.js";

Vue.config.productionTip = false
Vue.use(analytics, {
  apiKey: process.env.VUE_APP_AMPLITUDE_API_KEY
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')