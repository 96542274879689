<template>
  <div id="app" class="antialiased">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted: function() {
    this.$analytics.logEvent('app-loaded')
  }
}
</script>

<style>

</style>
<style src="./assets/tailwind.css">